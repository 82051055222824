import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChatWindow from './ChatWindow';

const Index = () => {
    const [userData, setUserData] = useState({});
    const [answers, setAnswers] = useState({});

    useEffect(() => {
        if (userData.userId) {
            const intervalId = setInterval(() => {
                axios.post(process.env.REACT_APP_API_URL + '/api/heartbeat', { userId: userData.userId })
                    .then(response => console.log('Heartbeat sent'))
                    .catch(error => console.error('Error sending heartbeat:', error));
            }, 300000); // Sends heartbeat every 5 minutes

            return () => clearInterval(intervalId);
        }
    }, [userData.userId]);

    return (
        <div className="chat-window">
            <ChatWindow
                userData={userData}
                setUserData={setUserData}
                answers={answers}
                setAnswers={setAnswers}
            />
        </div>
    );
};

export default Index;
