import React from 'react';
import { Layout } from "antd";
import Index from './components/Index';

const App = () => {
  return (
    <div className="app">
      <Layout>
        <Layout.Content>
          <Index />
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default App;
